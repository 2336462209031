import React, { useEffect, useState, lazy, Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom"; // Import useLocation to access URL parameters
import Sidebar from "../../Components/SideBar/SideBar";
import CustomAppBar from "../../Components/AppBar";
import axios from "axios";

// Lazy load components
const Charts = lazy(() => import("../../Components/Charts"));
const ProfessionalCardGrid = lazy(() => import("../../Components/CardsComponent"));
const EmployeeTable = lazy(() => import("../../Components/EmployeeTable/EmployeeTable"));
const EmployeeView = lazy(() => import("../../Components/EmployeeView/EmployeeView"));
const Departments = lazy(() => import("../../Components/Departments/Departments"));
const StatisticsAndApplications = lazy(() => import("../../Components/StatisticsAndApplications/StatisticsAndApplications"));
const TicketsForAdmin = lazy(() => import("../../Components/TicketsForAdmin/TicketsForAdmin"));
const EmployeeLeaveStatus = lazy(() => import("../../Components/EmployeeLeaveStatus/EmployeeLeaveStatus"));
const AddAdvertisement = lazy(() => import("../Ads/Ads"));
const AddPost = lazy(() => import("../../Components/AddPost/AddPost"));
const MeetingTimes = lazy(() => import("../../Components/MeetingTimes/MeetingTimes"));
const RequestsAndLeaves = lazy(() => import("../../Components/RequestsAndLeaves/RequestsAndLeaves"));
const OfficialHolidays = lazy(() => import("../../Components/OfficialHolidays/OfficialHolidays"));
const ProblemsWithUsers = lazy(() => import("../../Components/ProblemsWithUsers/ProblemsWithUsers"));

const HomeTest = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialComponent = query.get('view') || "Dashboard"; // Set initial component based on URL parameter

  const [selectedComponent, setSelectedComponent] = useState(initialComponent);
  const [problems, setProblems] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    document.body.style.margin = "0";
    document.documentElement.style.margin = "0";
    document.body.style.height = "100%";
    document.documentElement.style.height = "100%";

    const fetchProblemsAndUsers = async () => {
      try {
        const problemsResponse = await axios.get('https://backedndforelbatt.elbatt.com/getProblems.php');
        const usersResponse = await axios.get('https://backedndforelbatt.elbatt.com/getemployees.php');
        if (problemsResponse.data.success && usersResponse.data.status === 'success') {
          setProblems(problemsResponse.data.data);
          setUsers(usersResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching problems or users:', error);
      }
    };

    fetchProblemsAndUsers();
  }, []);

  const renderComponent = () => {
    switch (selectedComponent) {
      case "Dashboard": return <Suspense fallback={<CircularProgress />}><Charts /></Suspense>;
      case "EmployeeTable": return <Suspense fallback={<CircularProgress />}><EmployeeTable /></Suspense>;
      case "EmployeeView": return <Suspense fallback={<CircularProgress />}><EmployeeView /></Suspense>;
      case "Departments": return <Suspense fallback={<CircularProgress />}><Departments /></Suspense>;
      case "Complaints": return <Suspense fallback={<CircularProgress />}><ProblemsWithUsers problems={problems} users={users} /></Suspense>;
      case "RequestsAndLeaves": return <Suspense fallback={<CircularProgress />}><RequestsAndLeaves /></Suspense>;
      case "AddPost": return <Suspense fallback={<CircularProgress />}><AddPost /></Suspense>;
      case "MeetingTimes": return <Suspense fallback={<CircularProgress />}><MeetingTimes /></Suspense>;
      case "OfficialHolidays": return <Suspense fallback={<CircularProgress />}><OfficialHolidays /></Suspense>;
      case "StatisticsAndApplications": return <Suspense fallback={<CircularProgress />}><StatisticsAndApplications /></Suspense>;
      case "TicketsForAdmin": return <Suspense fallback={<CircularProgress />}><TicketsForAdmin /></Suspense>;
      case "EmployeeLeaveStatus": return <Suspense fallback={<CircularProgress />}><EmployeeLeaveStatus /></Suspense>;
      case "AddAdvertisement": return <Suspense fallback={<CircularProgress />}><AddAdvertisement /></Suspense>;
      default: return <Suspense fallback={<CircularProgress />}><Charts /></Suspense>;
    }
  };

  return (
    <div style={{ backgroundColor: "#141718", minHeight: "100vh", margin: "0", padding: "0", display: "flex", flexDirection: "row-reverse" }}>
      <Suspense fallback={<CircularProgress />}>
        <Sidebar selectedComponent={selectedComponent} setSelectedComponent={setSelectedComponent} unseenProblemsCount={3} />
      </Suspense>
      <div style={{ flex: 1, padding: "20px", color: "#fff", textAlign: "center" }}>
        <CustomAppBar />
        {renderComponent()}  // Render the selected component based on state
      </div>
    </div>
  );
};

export default HomeTest;
